import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/webdev/customer-projects/lachner-fe/cms_components/Accordion/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FormBody"] */ "/builds/webdev/customer-projects/lachner-fe/cms_components/ContactForm/formBody.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/webdev/customer-projects/lachner-fe/cms_components/DebugWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/webdev/customer-projects/lachner-fe/cms_components/Form/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/webdev/customer-projects/lachner-fe/cms_components/LinkArray/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/webdev/customer-projects/lachner-fe/cms_components/NavbarMenu/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/webdev/customer-projects/lachner-fe/cms_components/ProductItem/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/webdev/customer-projects/lachner-fe/cms_components/TimelineLayout/index.tsx");
;
import(/* webpackMode: "eager" */ "/builds/webdev/customer-projects/lachner-fe/components/Button/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/webdev/customer-projects/lachner-fe/components/CompanyInfo/Wrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DropdownLink"] */ "/builds/webdev/customer-projects/lachner-fe/components/DropdownLink/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/webdev/customer-projects/lachner-fe/components/LanguageSwitcher/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Modal"] */ "/builds/webdev/customer-projects/lachner-fe/components/Modal/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavigationCardMapLeft"] */ "/builds/webdev/customer-projects/lachner-fe/components/NavigationCard/UI/NavigationCardMapLeft.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavigationCardMapRight"] */ "/builds/webdev/customer-projects/lachner-fe/components/NavigationCard/UI/NavigationCardMapRight.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NavigationCardMapTop"] */ "/builds/webdev/customer-projects/lachner-fe/components/NavigationCard/UI/NavigationCardMapTop.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/builds/webdev/customer-projects/lachner-fe/components/ReferenceBox/index.tsx");
;
import(/* webpackMode: "eager" */ "/builds/webdev/customer-projects/lachner-fe/components/StatsCounter/StatsCounter.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Tabs"] */ "/builds/webdev/customer-projects/lachner-fe/components/Tabs/index.tsx");
;
import(/* webpackMode: "eager" */ "/builds/webdev/customer-projects/lachner-fe/node_modules/.pnpm/next@14.2.18_@babel+core@7.26.0_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
